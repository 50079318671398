import React from "react";
import styled from "styled-components";

const TeamIntroduction = () => {

    const teamMembers = [
        {
            id: 1,
            name: "Мартинов Олексій Георгійович",
            altText: "Мартинов Олексій Георгійович",
            role: "Президент Федерації воднолижного спорту і вейкбордингу України",
            imageUrl: "Martynov.png",
        },
        {
            id: 3,
            name: "Ковтун Олександр Михайлович",
            altText: "Ковтун Олександр Михайлович",
            role: "Перший віце-президент Федерації воднолижного спорту і вейкбордингу України",
            imageUrl: "KovtunOleksandr.png",
        },
        {
            id: 4,
            name: "Булгакова Марія Володимирівна",
            altText: "Булгакова Марія Володимирівна",
            role: "Генеральний секретар Федерації воднолижного спорту і вейкбордингу України",
            imageUrl: "Bulgakova.png",
        },
        {
            id: 5,
            name: "Король Марія Вікторівна",
            altText: "Король Марія Вікторівна",
            role: "Віце-президент технічного забезпечення Федерації воднолижного спорту і вейкбордингу України",
            imageUrl: "Korol.png",
        },
        {
            id: 6,
            name: "Ковтун Микита Олександрович",
            altText: "Ковтун Микита Олександрович",
            role: "Віце-президент по вейкбордингу Федерації воднолижного спорту і вейкбордингу України",
            imageUrl: "KovtunNikita.png",
        },
    ];

    return (
        <Container>
            <Header>
                <Title>Зустрічайте нашу команду</Title>
                <Subtitle>Познайомтеся з талановитими членами нашої Федерації воднолижного спорту і вейкбордингу України.</Subtitle>
            </Header>
            <Members>
                {teamMembers.map(member => (
                    <Member key={member.id}>
                        <Image src={member.imageUrl} alt={member.altText} loading="lazy" />
                        <Name>{member.name}</Name>
                        <Role>{member.role}</Role>
                    </Member>
                ))}
            </Members>
        </Container>
    );
};

const Container = styled.section`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const Header = styled.header`
    text-align: center;
    position: relative;
`;

const Title = styled.h2`
    color: #970711;
    padding-top: 110px;
    font: 400 30px 'Poppins', sans-serif;

    @media (max-width: 991px) {
        padding-top: 40px;
    }
`;

const Subtitle = styled.p`
    color: #042259;
    padding-top: 22px;
    font: 400 20px 'Poppins', sans-serif;

    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

const Members = styled.div`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 105px;

    @media (max-width: 991px) {
        margin-top: 40px;
    }
`;

const Member = styled.article`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 46%;
    color: #042259;
    font-weight: 600;
    text-align: center;
    padding: 0 20px;

    @media (max-width: 991px) {
        width: 100%;
        margin-top: 40px;
    }
`;

const Image = styled.img`  aspect-ratio: 1;
width: 300px;
max-width: 100%;
`;

const Name = styled.h3`
margin-top: 39px;
font-size: 30px;
`;

const Role = styled.p`
color: #ffb209;
align-self: stretch;
    margin-top: 10px;
font-size: 20px;
`;

export default TeamIntroduction;