import React from "react";
import styled from "styled-components";

const CoachesIntroduction = () => {
    const teamMembers = [
        {
            id: 1,
            name: "Хрісаненко Ольга Станіславівна",
            role: "Тренерка з водних лиж",
            imageUrl: "Khrisanenko.png",
            altText: "Хрісаненко Ольга Станіславівна"
        },
        {
            id: 2,
            name: "Шпак Степан Геннадійович",
            role: "Тренер з водних лиж",
            imageUrl: "Shpak.png",
            altText: "Шпак Степан Геннадійович"
        },
        {
            id: 3,
            name: "Лисенко Володимир Вікторович",
            altText: "Лисенко Володимир Вікторович",
            role: "Тренер з кабельного вейкбордингу",
            imageUrl: "Lisenko.png",
        },
        {
            id: 4,
            name: "Ожиганов Станіслав Євгенович",
            role: "Тренер з кабельного вейкбордингу",
            imageUrl: "Ozhiganov.png",
            altText: "Ожиганов Станіслав Євгенович"
        },
    ];

    return (
        <Container>
            <Header>
                <Title>Зустрічайте наших тренерів</Title>
                <Subtitle>Познайомтеся з талановитими тренерами нашої Федерації воднолижного спорту і вейкбордингу України.</Subtitle>
            </Header>
            <Members>
                {teamMembers.map(member => (
                    <Member key={member.id}>
                        <Image src={member.imageUrl} alt={member.altText} loading="lazy" />
                        <Name>{member.name}</Name>
                        <Role>{member.role}</Role>
                    </Member>
                ))}
            </Members>
        </Container>
    );
};

const Container = styled.section`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const Header = styled.header`
    text-align: center;
    position: relative;
`;

const Title = styled.h2`
    color: #970711;
    padding-top: 110px;
    font: 400 30px 'Poppins', sans-serif;

    @media (max-width: 991px) {
        padding-top: 40px;
    }
`;

const Subtitle = styled.p`
    color: #042259;
    padding-top: 22px;
    font: 400 20px 'Poppins', sans-serif;

    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

const Members = styled.div`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 105px;

    @media (max-width: 991px) {
        margin-top: 40px;
    }
`;

const Member = styled.article`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 46%;
    color: #042259;
    font-weight: 600;
    text-align: center;
    padding: 0 20px;

    @media (max-width: 991px) {
        width: 100%;
        margin-top: 40px;
    }
`;

const Image = styled.img`  aspect-ratio: 1;
width: 300px;
max-width: 100%;
`;

const Name = styled.h3`
margin-top: 39px;
font-size: 30px;
`;

const Role = styled.p`
color: #ffb209;
align-self: stretch;
margin-top: 10px;
font-size: 20px;
`;

export default CoachesIntroduction;