import * as React from "react";
import styled from "styled-components";

function FederationInfo() {
    return (
        <FederationInfoWrapper>
            <FederationImage
                loading="lazy"
                src="main.png"
                alt="Federation Image"
            />
            <FederationDescription>
                Федерація воднолижного спорту і вейкбордингу України була зареєстрована
                Міністерсвтом у справах молоді та спорту України 10 березня 1992 наказом
                № 41.
                <br />
                <br />
                19 липня 2007 року Федерація воднолижного спорту і вейкбордингу України
                отримала свідоцтво про реєстрацію № 2783 в Міністерстві юстиції.
            </FederationDescription>
        </FederationInfoWrapper>
    );
}

const FederationInfoWrapper = styled.section`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  color: #042259;
  font-weight: 600;
  text-align: center;
  padding: 0 20px;
`;

const FederationImage = styled.img`
  aspect-ratio: 1.96;
  object-fit: auto;
  object-position: center;
  width: 100%;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const FederationDescription = styled.p`
  font-family: Poppins, sans-serif;
  align-self: center;
  margin-top: 81px;
    max-width: 80%;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

export default FederationInfo;