import React from "react";
import styled from "styled-components";

const HomePage = () => {
    return (
        <HomePageContainer>
            <MainContent>
                <TextSection>
                    <Heading>Ласкаво просимо на офіційний сайт Федерації воднолижного спорту і вейкбордингу України!</Heading>
                    <Paragraph>Відкрийте для себе багату історію та місію Федерації воднолижного спорту і вейкбордингу України. Дізнайтеся про наше прагнення популяризувати та розвивати водні лижі по всій країні, створюючи спільноту захоплених спортсменів, тренерів та ентузіастів.</Paragraph>
                    <Navigation>
                        <NavLink href='#contacts'>Контакти</NavLink>
                        <NavLink href='#about'>Про нас</NavLink>
                    </Navigation>
                </TextSection>
                <ImageSection>
                    <ImageWrapper>
                        <Image src="head.png" alt="Воднолижний спорт" />
                    </ImageWrapper>
                </ImageSection>
            </MainContent>
        </HomePageContainer>
    );
};

const HomePageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    max-width: 100%;
    margin-top: 57px;
    @media (max-width: 991px) {
        padding-left: 20px;
        margin-top: 40px;
    }
`;

const Navigation = styled.nav`
    padding-top: 50px;
    display: flex;
    gap: 20px;
`;

const NavLink = styled.a`
    font-family: Poppins, sans-serif;
    font-weight: 600;
    background-color: #ffb209;
    padding: 17px 42px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    text-decoration: none; /* Remove underline */
    color: #042259; /* Change link color */
    display: inline-block; /* Make it behave like a block */
    cursor: pointer; /* Change cursor on hover */
    &:hover {
        background-color: #ffcc00; /* Change background color on hover */
    }
    @media (max-width: 991px) {
        padding: 17px 20px;
    }
`;

const MainContent = styled.main`
    display: flex;
    gap: 20px;
    padding: 40px;
    @media (max-width: 991px) {
        flex-direction: column;
    }
`;

const TextSection = styled.section`
    width: 45%;
    @media (max-width: 991px) {
        width: 100%;
    }
`;

const ImageSection = styled.section`
    width: 50%;
    @media (max-width: 991px) {
        width: 100%;
    }
`;

const ImageWrapper = styled.div`
    width: 100%;
    overflow: hidden; /* Ensuring the image doesn't overflow its container */
`;

const Image = styled.img`
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    @media (max-width: 991px) {
        margin-top: 40px;
    }
`;

const Heading = styled.h1`
    font-size: 35px;
    font-family: Poppins, sans-serif;
    color: #042259;
    font-weight: 600;
`;

const Paragraph = styled.p`
    font-family: Poppins, sans-serif;
    font-weight: 400;
    margin: 56px 14px 0 0;
    @media (max-width: 991px) {
        margin: 40px 10px 0 0;
    }
`;

export default HomePage;
