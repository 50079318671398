import React from "react";
import styled from "styled-components";

// Styled Components
const StyledSection = styled.section`
    margin-top: 10px;
    width: calc(100% - 40px); /* Adjust width to leave 20px space on each side */
    display: flex;
    margin-left: 10px; /* Add margin on left side */
    margin-right: 20px; /* Add margin on right side */
    justify-content: center;
    align-items: center;
    color: #042259;
    text-align: center;
    padding: 55px 0px 96px;
    font: 600 30px "Poppins", sans-serif;

    @media (max-width: 991px) {
        margin-top: 40px;
        padding: 40px 10px; /* Adjust left and right padding */
        font-size: 22px; /* Reduce font size for smaller screens */
    }
`;

const Footer = styled.footer`
    text-align: center;
    margin-top: 110px;
    @media (max-width: 991px) {
        margin-top: 40px;
    }
`;

const FooterContent = styled.div`
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 30px;
  color: #970711;
`;

// Main Component Function
function WaterSportsFederationInfo() {
    return (
        <>
            <Footer>
                <FooterContent>Про нас</FooterContent>
            </Footer>
            <StyledSection>
                Основною метою діяльності Федерації воднолижного спорту і вейкбордингу України є сприяння розвитку воднолижного спорту, підвищення ролі фізичної культури та спорту у всебічному і гармонійному розвитку особистості, зміцнення здоров’я громадян, формування здорового способу життя та захист спільних інтересів своїх членів.
            </StyledSection>
        </>
    );
}

export default WaterSportsFederationInfo;
