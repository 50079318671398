import React from 'react';
import styled from 'styled-components';

const YearlyChamp = () => {
    const images = [
        {
            src: "LogoIWWF.png",
            alt: "Decorative image",
        },
        {
            src: "LogoIWWF.png",
            alt: "First Column Image Description",
        },
        {
            src: "LogoIWWF.png",
            alt: "Second Column Image Description",
        },
    ];
    return (
        <>
            <StyledArticle>
                <p>
                    Щорічно проводиться Чемпіонат України з воднолижного спорту та Чемпіонат України з кабельного вейкбордингу та вейкскейтингу. Крім того, стрімко починає розвиватися вейксерфінг.
                </p>
                <p>
                    До складу воднолижного спорту входять чотири дисципліни: слалом, фігурне катання на водних лижах, стрибки з трампліну на водних лижах та багатоборство.
                </p>
            </StyledArticle>
            <GalleryContainer>
                <ImageContainer>
                    {images.map((image, index) => (
                        <ImageColumn key={index}>
                            <StyledImage loading="lazy" src={image.src} alt={image.alt} />
                        </ImageColumn>
                    ))}
                </ImageContainer>
            </GalleryContainer>
        </>
    );
};

const StyledArticle = styled.article`
    color: #042259;
    text-align: center;
    padding-top: 110px;
    padding-left: 40px;
    padding-right: 40px;
    font-weight: 600;
    font-size: 30px;
    font-family: Poppins, sans-serif;

    @media (max-width: 991px) {
        padding-top: 40px;
    }
`;

const GalleryContainer = styled.main`
    margin-top: 110px;
    width: 100%;
    padding: 0 20px;
    @media (max-width: 991px) {
        margin-top: 40px;
    }
`;

const ImageContainer = styled.section`
    display: flex;
    gap: 20px;
    justify-content: space-between; /* Ensure equal spacing between images */
    @media (max-width: 991px) {
        flex-direction: column;
        align-items: stretch;
        gap: 0;
    }
`;

const ImageColumn = styled.article`
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    line-height: normal;
    flex: 1; /* Ensure equal width for all columns */
    &:not(:first-child) {
        margin-left: 20px;
    }
    @media (max-width: 991px) {
        width: 100%;
        margin-top: 40px;
        &:not(:first-child) {
            margin-left: 0;
        }
    }
`;

const StyledImage = styled.img`
    aspect-ratio: 2;
    max-width: 200px;
    object-fit: cover;
    width: 100%;
    @media (max-width: 991px) {
        margin-top: 40px;
    }
`;

export default YearlyChamp;
