import './App.css';
import React, { useState, useEffect } from 'react';
import HomePage from "./components/HomePage";
import WaterSportsFederationInfo from "./components/AboutUs";
import WaterSportsInfo from "./components/AboutUs/WaterSportsInfo";
import YearlyChamp from "./components/AboutUs/YearlyChamp";
import TeamIntroduction from "./components/Team";
import CoachesIntroduction from "./components/Coaches";
import Achievements from "./components/Achievements";
import ContactWithFooter from "./components/Contacts";
import WebsiteHeader from "./components/Header";
import { AquaticSportsOverview, HydroSportsFederationOverview, AquaticEventSummary } from "./components/AboutUs/WaterSkiInfo";


function App() {
    const [showArrowUp, setShowArrowUp] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 100) {
                // Show arrow up button when scrolled down 100px from the top
                setShowArrowUp(true);
            } else {
                // Hide arrow up button when scrolled to the top
                setShowArrowUp(false);
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Remove scroll event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
      <div className="App">
          <WebsiteHeader/>
          <HomePage/>
          <div id="about">
              <WaterSportsFederationInfo/>
              <WaterSportsInfo/>
              <AquaticSportsOverview/>
              <HydroSportsFederationOverview/>
              <AquaticEventSummary/>
              <YearlyChamp/>
          </div>
          <div id="team">
              <TeamIntroduction/>
          </div>
          <div id="coaches">
              <CoachesIntroduction/>
          </div>
          <div id="achievements">
              <Achievements/>
          </div>
          <div id="contacts">
              <ContactWithFooter/>
          </div>
          {showArrowUp && (
              <div className="arrow-up" onClick={handleScrollToTop}>
                  <i className="fas fa-arrow-up"></i>
              </div>
          )}
      </div>
  );
}

export default App;
